import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Form1 from "./pages/Form1";
import Home from "./pages/Home";
import Cookie600 from "./pages/cookie600";
import Cookie600NOUSPS from "./pages/cookie600nousps";
import ThankYou from "./pages/ThankYou";
import NewThankYou from "./pages/newthankyou";
import Earn450 from "./pages/earn450";
import New from "./pages/new";
import NewC from "./pages/newcopy";
import carwrappromo from "./pages/carwrappromo";
import caedd from "./pages/caedd";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/shopper" component={Form1} />
        <Route exact={true} path="/autowrap" component={Cookie600} />
        <Route exact={true} path="/carwrappromo" component={carwrappromo} />
        <Route exact={true} path="/oreo" component={Cookie600NOUSPS} />
        <Route exact={true} path="/payroll" component={Earn450} />
        <Route exact={true} path="/Assistanceprogram" component={New} />
        <Route exact={true} path="/caedd" component={caedd} />
        <Route exact={true} path="/eddassistance" component={NewC} />
        <Route exact={true} path="/thanks" component={ThankYou} />
        <Route exact={true} path="/thankyou" component={NewThankYou} />
      </Switch>
    </Router>
  );
};

export default App;
