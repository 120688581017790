import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const NewThankYou = () => {
  useEffect(() => {
    document.title = "Campaign Mania - Thank you";
  }, []);
  return (
    <Container>
      <div className="text-center display-2 font-weight-bold text-success">
        Thank you for your submission, Kindly text "VALIDATE ME" to Jordan Gaston at (858) 365-0162
        to complete your application.
      </div>
    </Container>
  );
};

export default NewThankYou;
