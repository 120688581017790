import React from "react";

const Earn450Info = () => {
  return (
    <div>
      My name is Edward Trotman, I am a payroll contractor, I help companies
      process payroll for their workers, customer as pay check and also private
      companies who donates for the less privileged, Motherless kids. I have
      been into this payroll for over 13 years back am 47 years old. I'm looking
      for someone that can be trusted and reliable to work very well with good
      understanding as my Personal Assistant. This position I am offering is
      home-based and flexible; working with me is basically about instructions
      and following them.
      <br />
      <br /> WHAT YOU NEED TO DO FOR US?
      <br />
      <br /> You just need to have your own personal computer with printer.
      payroll form will be dispatched to your business e.mail address. and you
      are mandated to get the check printed before 24 hours so as to satisfy the
      best of our customers and client satisfaction. You will have to send the
      checks to the specified address through our FedEx Account(This cost you no
      charges) <br />
      <br />
      DURATION: <br />* Appointment is for a fixed term of 3 month initially,
      with renewal for a further period, up to a maximum of 1year, depending
      upon the officer's performance during the first term Grade:
      <br />
      <br /> SALARY: <br />
      <br />* Salary is $450 Weekly for a start , 2 days in a week (Monday and
      Friday). This is just an online work from home with flexible time
      <br />
      <br />
      REQUIREMENTS NEEDED TO GET STARTED : <br />
      <br />
      (1) Printer <br />
      (2) Docuguard business check form <br /> (3) Business standard envelopes (
      With no Window ) <br />
      (4) Regular white printing paper
      <br />
      <br /> If you're interested, sign up below to be contacted.
    </div>
  );
};

export default Earn450Info;
