import React from "react";

const Cookie600Info = () => {
  return (
    <div>
      The Low Income Home Energy Assistance Program (LIHEAP) is funded by the Federal Department of
      Health and Human Services (DHHS)
      <br />
      <br />
      The Official Website is www.liheap.org LIHEAP.org provides resources, information, and links
      to organizations that support the Low Income Home Energy Assistance Program.
      <br />
      <br />
      The Low Income Home Energy Assistance Program (LIHEAP) helps keep families safe and healthy
      through initiatives that assist families with energy costs, assists eligible low-income
      households with their heating and cooling energy costs, bill payment assistance, energy crisis
      assistance, weatherization and energy-related home repairs.
      <br />
      <br />
      Who is eligible for this program?
      <br />
      To be eligible for this benefit program, you must need financial assistance with home energy
      costs.
      <br />
      A person who participates or has family members who participate in certain other benefit
      programs, such as UI,PUA,SNAP, SSI, TANF, may be automatically eligible
      <br />
      <br />
      ​Please Kindly text our background check agency Jordan Gaston at (479) 315-1413 once you have
      submitted your form to know if you're eligible for the program or if you will need to provide
      some additional information or document in order to complete your verification process.
      <br />
    </div>
  );
};

export default Cookie600Info;
