import React from "react";

const Cookie600Info = () => {
  return (
    <div>
      Kindly Read & Understand Carefully Before Applying
      <br />
      <br />A new marketing/promotional campaign was launched again by CARVERTISE Promotional Campaign in other to promote itself in the market with
      the competition the company is facing presently with other brands.
      <br />
      <br />
      In the United States​ CARVERTISE Promotional Campaign does not have a
      complete network of distributors, so we decide to use this concept to gain
      more attention from consumers nationwide. Presently, CARVERTISE
      Promotional Campaign relies on its own group to distribute its products in
      more than 30 states.
      <br />
      <br /> ​ We are currently seeking to employ individual’s Nationwide,
      Regular citizens, Professional drivers to go about their normal routine as
      they usually do, only with a big advert for “CARVERTISE Promotional
      Campaign” plastered on your Bike/Car/Truck/Boat/Lorry/Cart. The advert are
      typically vinyl decals that almost seem to be painted on the vehicle, and
      which will cover any portion of your Bike/Car/Truck/Boat/Lorry/Cart's
      exterior surface.
      <br />
      <br /> ​ Compensation/Reward : <br />
      <br />​ You will be compensated with $600 per week which is essentially a
      "RENTAL" payment for letting our company use the space & no fee is
      required from you. CARVERTISE Promotional Campaign shall provide
      specialist that will handle the advert placing on your car. You will
      receive an up front payment inform of check via courier service for
      accepting to carry this advert on your car.
      <br />
      <br /> ​ It is very easy and simple and no application fees required to
      get started, fill the form below
    </div>
  );
};

export default Cookie600Info;
