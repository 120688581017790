import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import Info from "../components/newforminfo";
import { Form, Formik } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import * as Yup from "yup";
import FormControl from "../components/FormControl";
import RadioButtons from "../components/RadioButtons";
import axios from "axios";

const Form1 = () => {
  useEffect(() => {
    document.title = "LOW INCOME HOME ENERGY ASSISTANCE PROGRAM";
  }, []);

  // const [frontimage, setfrontimage] = useState("");

  // const onfrontChange = async (e) => {
  //   const base64 = await encodeFileBase64(e.target.files[0]);
  //   console.log(base64);
  //   // setfrontimage(base64.split(",")[1]);
  // };

  const encodeFileBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const initialValues = {
    firstname: "",
    lastname: "",
    streetaddress: "",
    city: "",
    state: "",
    postal: "",
    cellphone: "",
    email: "",
    dob: "",
    ssn: "",
    job: "",
    mstatus: "",
    dstatus: "",
    estatus: "",
    gstatus: "",
    country: "United States",
    frontimage: "",
    backimage: "",
    frontype: "",
    backtype: "",
    form: "LOW_INCOME_HOME_ENERGY_ASSISTANCE_PROGRAM_NEW_NEW",
  };

  const gradioOptions = [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
    { key: "Rather not say", value: "rather not say" },
  ];

  const mradioOptions = [
    { key: "Single", value: "single" },
    { key: "Married", value: "married" },
    { key: "Divorced", value: "divorced" },
  ];
  const radioOptions = [
    { key: "Employeed (Fully)", value: "employeed-fully" },
    { key: "Employeed (Partially)", value: "employeed-partially" },
    { key: "Unemployed", value: "Unemployed" },
  ];

  const Boleanoption = [
    { key: "Yes", value: "yes" },
    { key: "No", value: "no" },
  ];

  const validationSchema = Yup.object({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    streetaddress: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    postal: Yup.string()
      .required()
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Please Enter Valid USA Zip Code"),
    cellphone: Yup.number().required(),
    dob: Yup.string().required(),
    ssn: Yup.string().required(),
    email: Yup.string().email().required(),
    estatus: Yup.string().required(),
    mstatus: Yup.string().required(),
    gstatus: Yup.string().required(),
    dstatus: Yup.string().required(),
    frontimage: Yup.string().required(),
    backimage: Yup.string().required(),
  });

  const onSubmit = async (values, helpers) => {
    // console.log(values);
    const data = await axios.post(
      "https://oeun2av184.execute-api.us-east-1.amazonaws.com/default/FormNew",
      JSON.stringify({
        firstname: values.firstname,
        lastname: values.lastname,
        streetaddress: values.lastname,
        city: values.city,
        state: values.state,
        postal: values.postal,
        cellphone: values.cellphone,
        email: values.email,
        dob: values.dob,
        ssn: values.ssn,
        job: values.job,
        mstatus: values.mstatus,
        dstatus: values.dstatus,
        country: "United States",
        estatus: values.estatus,
        gstatus: values.gstatus,
        frontype: values.frontype,
        backtype: values.backtype,
        form: "LOW_INCOME_HOME_ENERGY_ASSISTANCE_PROGRAM_NEW_NEW",
        recaptcha: "bypass",
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const buffer1 = Buffer.from(
      values.frontimage.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    const buffer2 = Buffer.from(values.backimage.replace(/^data:image\/\w+;base64,/, ""), "base64");
    await axios.put(data.data.frontimageurl, buffer1, {
      headers: {
        "Content-Type": values.frontype,
        "Content-Encoding": "base64",
      },
    });
    await axios.put(data.data.backimageurl, buffer2, {
      headers: {
        "Content-Type": values.backtype,
        "Content-Encoding": "base64",
      },
    });
    window.location = "/thanks";
  };

  return (
    <Container>
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <Card className="mt-5">
            <Card.Header>LOW INCOME HOME ENERGY ASSISTANCE PROGRAM</Card.Header>
            <Card.Body>
              <Info />
              <div className="mb-3"></div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, isValid, dirty, setFieldValue, values, errors }) => (
                  <Form>
                    <h5>Name</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="firstname">
                          <FormControl
                            type="text"
                            name="firstname"
                            placeholder="First Name"
                            tabIndex={1}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group controlId="lastname">
                          <FormControl
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            tabIndex={2}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Address</h5>
                    <BootstrapForm.Group controlId="streetaddress">
                      <FormControl
                        type="text"
                        name="streetaddress"
                        placeholder="Street Address"
                        tabIndex={3}
                      />
                    </BootstrapForm.Group>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="city">
                          <FormControl type="text" name="city" placeholder="City" tabIndex={4} />
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group controlId="state">
                          <FormControl type="text" name="state" placeholder="State" tabIndex={5} />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="postal">
                          <FormControl
                            type="text"
                            name="postal"
                            placeholder="Postal"
                            tabIndex={6}
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col>
                        <BootstrapForm.Group controlId="country">
                          <FormControl
                            type="text"
                            name="country"
                            placeholder="Country"
                            disabled
                            tabIndex={7}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Cell Number</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="cellphone">
                          <FormControl
                            type="text"
                            name="cellphone"
                            placeholder="(000) 000-0000"
                            tabIndex={8}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Email Addresss</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="email">
                          <FormControl
                            type="text"
                            name="email"
                            placeholder="example@example.com"
                            tabIndex={9}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Date of Birth</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="dob">
                          <FormControl
                            type="text"
                            name="dob"
                            placeholder="MM-DD-YYYY"
                            tabIndex={10}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>SSN</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="ssn">
                          <FormControl
                            type="text"
                            name="ssn"
                            placeholder="Social Security Number"
                            tabIndex={11}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Gender</h5>
                    <Row>
                      <Col>
                        <RadioButtons
                          control="radio"
                          tabIndex={12}
                          name="gstatus"
                          options={gradioOptions}
                        />
                      </Col>
                    </Row>

                    <h5>Martial Status</h5>
                    <Row>
                      <Col>
                        <RadioButtons
                          control="radio"
                          tabIndex={13}
                          name="mstatus"
                          options={mradioOptions}
                        />
                      </Col>
                    </Row>

                    <h5>Employement Status</h5>
                    <Row>
                      <Col>
                        <RadioButtons
                          control="radio"
                          tabIndex={14}
                          name="estatus"
                          options={radioOptions}
                        />
                      </Col>
                    </Row>
                    <h5>Describe your job</h5>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="job">
                          <FormControl type="text" name="job" placeholder="" tabIndex={15} />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>

                    <h5>Do you have any form of disability?</h5>
                    <Row>
                      <Col>
                        <RadioButtons
                          control="radio"
                          tabIndex={16}
                          name="dstatus"
                          options={Boleanoption}
                        />
                      </Col>
                    </Row>
                    <h5>Choose photo of your valid driver's license or state ID (FRONT)</h5>
                    <BootstrapForm.Group controlId="frontimagepicker">
                      <FormControl
                        type="file"
                        name="frontimagepicker"
                        accept="image/*"
                        onChange={async (e) => {
                          if (e.target.files.length !== 0) {
                            const base64 = await encodeFileBase64(e.target.files[0]);
                            setFieldValue("frontimage", base64);
                            setFieldValue(
                              "frontype",
                              base64.split(",")[0].replace("data:", "").replace(";base64", "")
                            );
                          }
                        }}
                      />
                    </BootstrapForm.Group>

                    <h5>Choose photo of your valid driver's license or state ID (BACK)</h5>
                    <BootstrapForm.Group controlId="backimagepicker">
                      <FormControl
                        type="file"
                        name="backimagepicker"
                        accept="image/*"
                        onChange={async (e) => {
                          if (e.target.files.length !== 0) {
                            const base64 = await encodeFileBase64(e.target.files[0]);
                            setFieldValue("backimage", base64);
                            setFieldValue(
                              "backtype",
                              base64.split(",")[0].replace("data:", "").replace(";base64", "")
                            );
                          }
                        }}
                      />
                    </BootstrapForm.Group>
                    <br />
                    <Button
                      type="submit"
                      tabIndex={17}
                      block
                      disabled={!(dirty && isValid) || isSubmitting}
                    >
                      {isSubmitting ? <Spinner animation="border" size="sm" /> : "Submit"}
                    </Button>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                    <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Form1;
